<template>
  <div :id="isThumbnail ? `thumbnail-${block.id}` : `player-${block.id}`" class="h-full" :style="{ maxHeight: isThumbnail ? 'calc(100% - 2.5rem)' : 'none' }">
    <div
      :class="youtubeWrapperClass"
      data-provider="youtube"
      :style="youtubeStyle">
      <div :class="youtubeVideoClass" data-youtube-target="video"></div>
    </div>
  </div>
</template>

<script>
import { toRef, onMounted, watch, ref } from 'vue'
import { useEmbedHelpers } from '../../composables/useEmbedHelpers'
import { useYouTubePlayer } from '../../composables/useYouTubePlayer'

export default {
  name: 'YouTubeEmbed',
  props: {
    block: {
      type: Object,
      required: true
    },
    isThumbnail: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const embedRef = toRef(props.block, 'embed')
    const { initYouTubePlayer } = useYouTubePlayer(props.block.id)

    const { youtubeWrapperClass, youtubeStyle, youtubeVideoClass } = useEmbedHelpers(embedRef)

    const getVideoId = () => {
      return props.block.embed.external_embed_id || new URL(props.block.embed.original_url).searchParams.get('v')
    }

    onMounted(() => {
      if (window.YT) {
        initYouTubePlayer(getVideoId(), {
          start_seconds: props.block.embed.start,
          end_seconds: props.block.embed.end,
          thumbnail: props.isThumbnail
        })
      } else {
        console.error('YouTube API not loaded')
      }
    })

    watch(
      () => [props.block.embed.original_url, props.block.embed.start, props.block.embed.end],
      () => {
        initYouTubePlayer(getVideoId(), {
          start_seconds: props.block.embed.start,
          end_seconds: props.block.embed.end,
          thumbnail: props.isThumbnail
        })
      }
    )

    return {
      youtubeWrapperClass,
      youtubeStyle,
      youtubeVideoClass,
    }
  }
}
</script>
