<template>
  <button @click="addSlide" class="w-full h-full">
    <slot>
    </slot>
  </button>
</template>

<script>
import { inject } from 'vue'

export default {
  name: 'AddSlideButton',
  setup() {
    const addSlide = inject('addSlide')

    return {
      addSlide
    }
  }
}
</script>
