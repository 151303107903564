<template>
  <div
    ref="thumbnailContainer"
    class="flex flex-col h-full overflow-y-auto bg-gray-100 w-56 pl-3 pr-5 pb-2"
  >
    <div
      v-for="(slide, index) in slides"
      :key="slide.id"
      class="thumbnail-container flex items-center relative pl-6 mb-4 cursor-pointer"
      :class="{ 'active': index === currentSlideIndex }"
      :data-slide-id="slide.id"
      :data-slide-update-path="slide.update_path"
      @click="selectSlide(index)"
    >
      <div class="slide-number absolute left-0 flex items-center justify-center font-bold text-xs text-gray-500">{{ slide.position }}</div>
      <div class="thumbnail-item w-full relative">
        <section :class="['thumbnail-wrapper absolute top-0 left-0 w-full h-full overflow-hidden border border-gray-300', textContrastClass(slide.layout.background_color)]" :style="{ background: slide.layout.background_color }">
          <Slide
            :key="`thumbnail-${index}`"
            :slide="slide"
            :is-thumbnail="true"
          />
        </section>
      </div>
    </div>
    <AddSlideButton>
      <div class="flex items-center justify-center bg-gray-100 ml-6 h-20 border border-gray-300">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
        </svg>
      </div>
    </AddSlideButton>
  </div>
</template>

<script>
import Slide from '../slides/Slide.vue'
import { textContrastClass } from '@/helpers'
import { watch, ref } from 'vue'
import debounce from 'debounce'
import { useSortable } from '@vueuse/integrations/useSortable'
import { useSlideUpdate } from '../../composables/useSlideUpdate'
import AddSlideButton from '../slides/AddSlideButton.vue'

export default {
  name: 'SlidesThumbnailPanel',
  props: {
    slides: {
      type: Array,
      required: true,
    },
    currentSlideIndex: {
      type: Number,
      required: true,
    },
  },
  components: {
    Slide,
    AddSlideButton
  },
  setup(props, { emit }) {
    const thumbnailContainer = ref(null)
    const { updateSlide } = useSlideUpdate()

    const scrollToThumbnail = debounce((index) => {
      const nextThumbnail = document.querySelector(`.thumbnail-container:nth-child(${index + 1})`)
      if (nextThumbnail) {
        nextThumbnail.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
      }
    }, 300)

    const selectSlide = (index) => {
      emit('select-slide', index)
    }

    watch(() => props.currentSlideIndex, (newIndex) => {
      scrollToThumbnail(newIndex)
    })

    useSortable(thumbnailContainer, props.slides, {
      animation: 150,
      onEnd: ({ item, newIndex, to }) => {
        const movedSlideId = item.dataset.slideId
        const movedUpdatePath = item.dataset.slideUpdatePath

        updateSlide(movedUpdatePath, { slide: { position: newIndex + 1 } })
      }
    })

    return {
      textContrastClass,
      selectSlide,
      thumbnailContainer
    }
  },
}
</script>

<style scoped>
.thumbnail-item {
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.thumbnail-wrapper :deep(.actual-slide) {
  transform: scale(0.128);
  transform-origin: top left;
  width: calc(100% / 0.128);
  height: calc(100% / 0.128);
  pointer-events: none;
}

.slide-number {
  top: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

.thumbnail-container:first-child {
  padding-top: 2px;
}

.active .thumbnail-item {
  outline: 2px solid #8ee5fb;
}
</style>
