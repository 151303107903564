<template>
  <div
    :id="`block-${block.id}`"
    :style="outerStyles"
    :class="outerClasses"
    @mousedown="setCurrentEditor"
  >
    <div class="relative h-full w-full" data-inline-svg="false">
      <!-- Text -->
      <div v-if="block.block_type == 'text'" data-type="text" ref="blockRef">
        <ContentEditor :content="block.html_content" :onUpdate="(html) => onUpdatedBlock(html, 'html_content')" @editor-created="updateEditor" :is-thumbnail="isThumbnail" />
      </div>

      <!-- Spark -->
      <SparkPlaceholder v-else-if="block.has_spark" :spark_image_url="block.spark_image_url" />

      <!-- Section -->
      <Section v-else-if="block.block_type == 'section'" :section="block.section" :block="block" :is-thumbnail="isThumbnail" />

      <!-- Embed -->
      <Embed v-else-if="block.block_type == 'embed'" :block="block" :is-thumbnail="isThumbnail" />

      <!-- Image w/attachment -->
      <div v-else-if="isImageWithAttachment" class="relative w-full h-full object-cover rounded-lg overflow-hidden p-6" :style="block.inner_style">
        <img :src="getStaticImageUrl(isThumbnail)" class="w-full h-full object-cover rounded-lg" />
        <ImageUploadOverlay v-if="block.editable" :block="block" wrapperClasses="p-6" />
      </div>

      <!-- Image w/o attachment -->
      <div v-else-if="isImageWithoutAttachment" class="flex h-full w-full justify-center items-center p-6">
        <img :src="getStaticImageUrl(isThumbnail)" class="w-full h-full object-cover rounded-lg" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useBlockHelpers } from '../../../composables/useBlockHelpers'
import { useBlockEditor } from '../../../composables/useBlockEditor'
import SparkPlaceholder from './SparkPlaceholder.vue'
import Embed from './Embed.vue'
import Section from './Section.vue'
import ContentEditor from '../../editor/ContentEditor.vue'
import ImageUploadOverlay from '../../images/ImageUploadOverlay.vue'

export default {
  name: 'RelativeBlock',
  props: {
    block: {
      type: Object,
      required: true
    },
    isThumbnail: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SparkPlaceholder,
    Embed,
    Section,
    ContentEditor,
    ImageUploadOverlay
  },
  setup(props) {
    const { parseStyleString, isImageWithAttachment, isImageWithoutAttachment, getStaticImageUrl } = useBlockHelpers(props.block)
    const { setCurrentEditor, updateEditor, blockRef, onUpdatedBlock } = useBlockEditor(props.block)

    const outerStyles = computed(() => ({
      ...parseStyleString(props.block.outer_style),
      position: 'unset'
    }))

    const outerClasses = computed(() => [
      'wellio-block',
      'block',
      'text-inherit',
      'h-full',
      'w-full',
      `self-${props.block.align}`,
      `justify-self-${props.block.justify}`,
      `z-100`
    ])

    return {
      outerStyles,
      outerClasses,
      isImageWithAttachment,
      isImageWithoutAttachment,
      setCurrentEditor,
      updateEditor,
      blockRef,
      onUpdatedBlock,
      getStaticImageUrl
    }
  }
}
</script>
