<template>
  <div class="flex flex-col text-sm justify-center relative items-center" data-type="section">
    <div class="h3 font-extrabold z-200 text-center" ref="blockRef">
      <ContentEditor
        :content="section.title"
        :onUpdate="(html) => onUpdatedBlock(html, 'section_title')"
        forcedTag="h3"
        :is-thumbnail="isThumbnail"
      />
    </div>

    <div class="p w-2/3 font-light z-200 text-center" style="margin: 15px 0 30px 0;">
      <ContentEditor
        :content="section.subtitle"
        :onUpdate="(html) => onUpdatedBlock(html, 'section_subtitle')"
        forcedTag="div"
        :is-thumbnail="isThumbnail"
      />
    </div>

    <!-- Action Bar -->
    <div class="flex items-center gap-6 w-fit px-10 justify-around bg-white p-2 rounded-lg text-gray-900 text-sm">
      <!-- Toggle responses button -->
      <div class="flex items-center text-xl">
        <button
          type="button"
          class="bg-gray-200 relative z-[300] inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
          role="switch"
          aria-checked="false">
          <span class="sr-only">Use setting</span>
          <span aria-hidden="true" class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
        </button>

        <span class="ml-3 text-lg"> Responses </span>
      </div>

      <!-- Completed details x/x -->
      <div class="flex items-center text-xl">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>

        <span class="mx-3 text-lg"> x/x complete</span>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
      </div>

      <!-- Toggle locked button -->
      <div class="flex items-center text-xl">
        <button
          type="button"
          class="bg-gray-200 relative z-[300] inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
          role="switch"
          aria-checked="false">
          <span class="sr-only">Use setting</span>
          <span aria-hidden="true" class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
        </button>

        <span class="ml-3 text-lg"> Locked </span>
      </div>

      <!-- Dropdown -->
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-700 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
      </svg>
    </div>
  </div>

  <div class="flex flex-wrap justify-center pt-10 pb-20 relative" data-questions>
    <Question
      v-for="(question, index) in section.questions"
      :key="index"
      :question="question"
    />
  </div>
</template>

<script>
import Question from '../questions/Question.vue'
import ContentEditor from '../../editor/ContentEditor.vue'
import { useBlockEditor } from '../../../composables/useBlockEditor'

export default {
  name: 'Section',
  props: {
    section: {
      type: Object,
      required: true
    },
    block: {
      type: Object,
      required: true
    },
    isThumbnail: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Question,
    ContentEditor
  },
  setup(props) {
    const { onUpdatedBlock, setCurrentEditor, updateEditor, blockRef } = useBlockEditor(props.block)

    return {
      onUpdatedBlock,
      updateEditor,
      setCurrentEditor,
      blockRef
    }
  }
}
</script>
