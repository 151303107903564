import { inject } from 'vue'
import { patch } from '@rails/request.js'
import { useSaveStatus } from './useSaveStatus'

export function useSlideUpdate() {
  const updateSlidePositions = inject('updateSlidePositions')
  const { setStatus, setStatusToLastSaved, setIsSaving } = useSaveStatus()

  const updateSlide = async (updatePath, formData) => {
    const startTime = Date.now()
    setStatus('Saving...')
    setIsSaving(true)

    try {
      const response = await patch(updatePath, { body: formData, responseKind: 'json' })

      if (response.ok) {
        await handleSuccessfulUpdate(response, startTime)
      } else {
        handleUpdateError(await response.json)
      }
    } catch (error) {
      handleUpdateError(error)
      throw error
    }
  }

  const handleSuccessfulUpdate = async (response, startTime) => {
    const sortedSlidesIds = await response.json
    updateSlidePositions(sortedSlidesIds)

    const elapsedTime = Date.now() - startTime
    const remainingTime = Math.max(0, 1000 - elapsedTime)
    setTimeout(setStatusToLastSaved, remainingTime)
    setIsSaving(false)
  }

  const handleUpdateError = (error) => {
    console.error('Error updating slide:', error)
    setStatus('⚠️ Error saving')
    setIsSaving(false)
  }

  return { updateSlide }
}
